/** xs **/
@media (min-width: 344px) and (max-width: 543px) {
.film-data, .film-dates {
    float: none;
    width: 100%;
}

.film-data .poster {
    float: left;
}

.film-data .item {
    clear: right;
    float: right;
    margin-right: 40px;
    width: 100px;
}

.film-main {
    clear: both;
}

}