.aka {
    display: block;
    font-size: 0.7em;
    margin-left: 10px;
}

.film-data {
    float: left;
    margin: 0 20px 20px 20px;
    width: 147px;
}

.film-data .poster {
    margin: 10px 25px 0 25px;
}

.film-data .item {
    margin-top: 20px;
}

.film-rating-stars {
    color: #DF7124;
    font-size: 16px;
    height: 16px;
}

.film-dates {
    float: right;
    margin: 0 20px 20px 20px;
    width: 240px;
}

.film-main {
}

.crt-reason {
    font-size: 10px;
    padding: 2px 5px;
}

.film-status {
    background: url(images/status-sprites.jpg) no-repeat 0 0;
    color: #333333;
    font-size: 1em;
    font-style: italic;
    font-weight: bold;
    line-height: 10px;
    padding: 5px 0;
    text-align: center;
    width: 100px;
}
.fs-cancelled, .fs-not-planned { padding-top: 78px }
.fs-postponed { padding-top: 78px; background-position: -900px 0 }
.fs-confirmed { padding-top: 72px; background-position: -100px 0 }
.fs-complete { padding-top: 68px; background-position: -195px 0 }
.fs-development { padding-top: 82px; background-position: -300px 0 }
.fs-post-production { padding-top: 55px; background-position: -400px 0 }
.fs-pre-production { padding-top: 75px; background-position: -500px 0 }
.fs-re-release, .fs-released { display: none }
.fs-rumoured { padding-top: 72px; background-position: -600px 0 }
.fs-shooting { padding-top: 86px; background-position: -700px 0 }
.fs-unknown { padding-top: 56px; background-position: -800px 0 }

.film-date {
    width: 200px;
    height: 95px;
    padding: .75rem 10px .75rem 84px;
    zoom: 100%;
    float: left;
    text-align: center;
    position: relative;
    white-space: normal;
    background: url(images/rd-icns.png) no-repeat
}
.film-date h4 {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;
}
.film-date p {
    font-size: 14px;
    line-height: 110%;
}
.film-date-cinema { background-position: 10px 10px }
.film-date-online { background-position: 0 -592px  }
.film-date-festival { background-position: 10px -248px }
.film-date-dvd { background-position: -5px -76px }
.film-date-disc { background-position: 0 -334px }
.buy-disc { position: absolute; bottom: 2px; left: 14px; padding: 10px 30px; background: url(images/amzn.png) no-repeat; font-weight: bold }
.film-date-bluray { background-position: 0 -163px }
.film-date-tv { background-position: 0 -422px }
.film-date-premiere { background-position: 0 -506px }

.film-cinema-listings-container {
    clear: both;
    margin: 10px -20px 0 -20px;
    padding: 50px;
}

#trailer {
    margin: 20px auto 10px auto;
}
