@media (min-width: 544px) {

}

@media (max-width: 767px) {
	.header {
        background-position: -60px -10px;
        height: 82px;
	}
	.minimized .header {
	    background: #1B5409;
	    left: 0;
	    height: 30px;
	    position: fixed;
	    top: 0;
	    width: 100%;
	    z-index: 5;
	}
	.minimized .header #hdr-icons, .minimized .footer {
        display: none;
	}
	#header-home-link {
	    left: 30px;
	    top: 8px;
	}
	.sidebar, .nav-bar {
	   top: 54px;
	}
    .sidebar .form-control {
        display: none;
    }
    .sidebar form {
        top: 0;
    }
    .sidebar .fa-search {
        background: #2B2B2B;
        background: RGBA(22, 22, 22, 0.6);
        border-radius: 50% !important;
        font-size: 14px !important;
        height: 20px;
        width: 20px;
    }
    .sidebar .fa-search:hover {
        background: #FFF;
    }
}
