.change-name {
	margin-top: 5px;
}
.change-date {
	font-size: 14px;
}

.film-slider {
    height: 160px;
    overflow: auto;
    position: relative;
}
.film-slider-scroll-btn {
	background: #333;
	color: #F7F7F7;
	opacity: 0.75;
	padding: 10px 5px;
	position: absolute;
	text-decoration: none;
	top: 50px;
}
.film-slider-scroll-btn:hover {
	color: #F7F7F7;
	opacity: 1;
}
.film-slider-scroll-btn.disabled {
	display: none;
}
.film-slider-scroll-btn.fa-chevron-left {
    border-radius: 0 5px 5px 0;
	left: 0;
}
.film-slider-scroll-btn.fa-chevron-right {
    border-radius: 5px 0 0 5px;
	right: 0;
}
.film-holder {
    left: 0;
    position: absolute;
    top: 0;
}
.film-holder a {
    display: block;
    float: left;
    margin-right: 10px;
    width: 97px;
}
.film-holder a:last-child {
	margin-right: 0;
}