@media (max-width: 543px) {
    .header {
        z-index: 20;
    }
    .body {
       padding-bottom: 40px;
    }
    .minimized .body {
        padding-bottom: 0;
    }
    .footer {
        display: none;
    }
    #hdr-icons {
        bottom: 0;
        left: 0;
        position: fixed;
        right: auto;
        top: auto;
        width: 100%;
    }
    .hdr-icon {
        color: #FFFFFF;
        cursor: pointer;
        height: 30px;
        margin: 0;
        padding: 0 10px;
        position: relative;
        top: 0;
        width: 33.3333%;
        z-index: 20;
    }
    .hdr-icon-facebook {
        background-color: #3B5998;
        background-position: 50% 0;
    }
    .hdr-icon-facebook:hover {
        color: #D4F2FF;
    }
    .hdr-icon-twitter {
        background-color: #6CADDE;
        background-position: 50% -30px;
    }
    .hdr-icon-twitter:hover {
        color: #D2FFFF;
    }
    .hdr-icon-google-plus {
        background-color: #DD4B39;
        background-position: 50% -60px;
    }
    .hdr-icon-google-plus:hover {
        color: #FFE4D2;
    }
	.hdr-icon-user, .hdr-icon-register {
        background-color: #4D983B;
	    background-position: 50% -120px;
	}
    .hdr-icon-user:hover, .hdr-icon-register:hover {
        color: #DFFFFF;
    }
    .hdr-icon-rss {
        background-color: #FF7900;
        background-position: 50% -150px;
    }
    .hdr-icon-rss:hover {
        color: #FFFFCC;
    }
}

/*@media (max-width: 767px) {
	html, body {
        height: auto;
        min-width: 460px;
        overflow: auto;
	}
    .sidebar-link {
        font-size: 14px;
    }
	.header {
	    background-position: -55px -10px;
        height: 100px;
	}
    .container {
        height: auto;
        margin: 0;
    }
}
*/
